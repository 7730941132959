<template>
  <form id="checkoutForm">
    <input type="hidden" name="omiseToken" />
    <input type="hidden" name="omiseSource" />
    <!-- <button type="submit" id="checkoutButton">
      Checkout
    </button> -->
  </form>
</template>

<script>
import payment from '@/services/payment'

export default {
  mounted() {
    let amount = sessionStorage.getItem('amount')
    let totalAmount = Number(amount) * 100
    OmiseCard.configure({
      publicKey: 'pkey_test_5q0q2gzgqp8i1mbz8wc',
      image: 'https://cdn.omise.co/assets/dashboard/images/omise-logo.png',
      currency: 'THB',
    })

    OmiseCard.open({
      publicKey: 'pkey_test_5q0q2gzgqp8i1mbz8wc',
      amount: totalAmount,
      onCreateTokenSuccess: (nonce) => {
        if (nonce.startsWith('tokn_')) {
          console.log(nonce)

          this.creditcard(nonce)
        }
      },
      onFormClosed: () => {
        this.$router.push('/payment')
      },
    })
  },
  methods: {
    creditcard(token) {
      let amount = sessionStorage.getItem('amount')
      let totalAmount = Number(amount) * 100

      let data = { omiseToken: token, amount: totalAmount }

      payment({
        url: '/omise/payment/v1.0/test/creditcard',
        data: data,
        method: 'post',
      }).then((res) => {
        console.log(res)
        if (res.data.error.code === 0) {
          sessionStorage.setItem('chargeId', res.data.data.id)
          //this.qrcode = res.data.data.uri
          window.location.replace(res.data.data.uri)
        }
      })
    },
  },
}
</script>
